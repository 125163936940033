import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from './Layouts/Header/Header';
import Home from './Pages/Home/Home';
import Footer from './Layouts/Footer/Footer';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import Products from './Pages/Products/Products';


function App() {
  return (
    <div className="">
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/products' element={<Products/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
