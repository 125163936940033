import React from 'react';
import people from '../../Assets/Images/sync-people.PNG'
const SyncInfo = () => {
    return (
        <section className='container'>

            <div className='grid lg:grid-cols-3 '>
                <div className='' style={{
                    backgroundPosition: '50%',
                    backgroundImage: `url(${people})`,
                    wide: '400px',
                    height: '400px',
                }}>1</div>
                <div className='bg-blue-600'>
                    <div className=''>
                        <p className='text-white font-medium mt-48 px-12'>6828 followers on LinkedIn. Empowering businesses with software solutions that help serve their customers better.  </p>
                    </div>
                </div>
                <div className='bg-white'>
                    <div className=''>
                        <h1 className='text-9xl font-bold mt-36 px-12'>45<span className='text-9xl font-bold'>+</span></h1>
                        <p className=' px-12 pt-5'>Customers Worldwide </p>
                    </div>
                </div>
                
            </div>

        </section>
    );
};

export default SyncInfo;