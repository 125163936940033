import React from 'react';

const SoftMethodologies = () => {
    return (
        <div>
            <div className='container my-12 px-6 w-5/6 mx-auto'>
                <div className='text-center'>
                    <h1 className='text-5xl font-semibold mb-6'>Software Development Methodologies</h1>
                    <div className='flex justify-center items-center '>
                        <p className='mb-6 p-4 text-xl w-2/3'>
                            Successful projects are managed well. To manage a project efficiently,
                            the manager or development team must choose the software development
                            methodology that will work best for the project at hand. All methodologies
                            have different strengths and weaknesses and exist for different reasons.
                            Here’s an overview of the most commonly used software development methodologies
                            and why different methodologies exist.
                        </p>
                    </div>
                </div>
                <section className='grid lg:grid-cols-2 gap-5 '>
                    <div className="flex justify-center   ">
                        <div className=" block p-6 rounded-lg shadow-lg bg-white max-w-lg ">
                            <div className='flex justify-center items-center'>
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                            </div>
                            <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-2">Agile</h5>
                            <p className="text-gray-700 text-base mb-4">
                                Teams use the agile development methodology to minimize risk (such as bugs, cost overruns, and changing requirements) when adding new functionality. In all agile methods, teams develop the software in iterations that contain mini-increments of the new functionality. There are many different forms of the agile development method, including scrum, crystal, extreme programming (XP), and feature-driven development (FDD).
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center   ">
                        <div className=" block p-6 rounded-lg shadow-lg bg-white max-w-lg ">
                            <div className='flex justify-center items-center'>
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                            </div>
                            <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-2">DevOps</h5>
                            <p className="text-gray-700 text-base mb-4">
                                DevOps is not just a development methodology but also a set of practices that supports an organizational culture. DevOps deployment centers on organizational change that enhances collaboration between the departments responsible for different segments of the development life cycle, such as development, quality assurance, and operations.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center   ">
                        <div className=" block p-6 rounded-lg shadow-lg bg-white max-w-lg ">
                            <div className='flex justify-center items-center'>
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                            </div>
                            <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-2">Waterfall</h5>
                            <p className="text-gray-700 text-base mb-4">
                                Many consider the waterfall method to be the most traditional software development method. The waterfall method is a rigid linear model that consists of sequential phases (requirements, design, implementation, verification, maintenance) focusing on distinct goals. Each phase must be 100% complete before the next phase can start. There’s usually no process for going back to modify the project or direction.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center   ">
                        <div className=" block p-6 rounded-lg shadow-lg bg-white max-w-lg ">
                            <div className='flex justify-center items-center'>
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                            </div>
                            <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-2">Rapid</h5>
                            <p className="text-gray-700 text-base mb-4">
                                Rapid application development (RAD) is a condensed development process that produces a high-quality system with low investment costs. Scott Stiner, CEO and president of UM Technologies, said in Forbes, “This RAD process allows our developers to quickly adjust to shifting requirements in a fast-paced and constantly changing market.” The ability to quickly adjust is what allows such a low investment cost.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SoftMethodologies;