import React from 'react';
import ECommerce from '../../Components/Products/ECommerce';
import ELearning from '../../Components/Products/ELearning';
import ERPSolutions from '../../Components/Products/ERPSolutions';
import FantasyApp from '../../Components/Products/FantasyApp';
import ProductsBanner from '../../Components/Products/ProductsBanner';

const Products = () => {
    return (
        <div>
            <ProductsBanner/>
            <ERPSolutions/>
            <ELearning/>
            <ECommerce/>
            <FantasyApp/>
        </div>
    );
};

export default Products;