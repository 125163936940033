import React from 'react';
import OurFeatures from '../../Components/Services/OurFeatures';
import OurServices from '../../Components/Services/OurServices';
import ServicesBanner from '../../Components/Services/ServicesBanner';
import SoftDevelop from '../../Components/Services/SoftDevelop';
import SoftMethodologies from '../../Components/Services/SoftMethodologies';


const Services = () => {
    return (
        <div>
            <ServicesBanner/>
            <SoftDevelop/>
            <OurFeatures/>
            <OurServices/>
            <SoftMethodologies/>
        </div>
    );
};

export default Services;