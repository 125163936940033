import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneFlip, faEnvelope, faLocationDot, faGlobe } from '@fortawesome/free-solid-svg-icons';
const ContactBanner = () => {
    return (
        <div>
            <div className="p-12 text-center relative overflow-hidden bg-no-repeat bg-cover "
                style={{
                    backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/02/11/09/58/1000_F_211095871_r74aavHAmiLk8xeCuL3SBfnOivgsj0ZQ.jpg')`,
                    height: '700px'
                }} >
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                    <section className='mt-36  grid lg:grid-cols-2 gap-5'>
                        <div className='px-36 text-start text-white '>
                            <h1 className='mb-8 text-3xl  uppercase'>Contact Us</h1>
                            <p className='mb-8'>Please send us your requirements and we'll get <br /> back to you at the earliest.</p>
                            <div className='mb-4'>
                                <p className='mb-2 font-bold'>Sync Seeker Bangladesh Limited</p>

                                <div className='mb-3'>
                                    <FontAwesomeIcon className='mr-2' icon={faLocationDot} />
                                    <span className='ml-1'>House 57 (1st Floor,C-1), Road 6</span> <br /><span className='ml-6'> Block-C, SJDL, Samoli Housing </span><br /><span className='ml-6'> Adabor, Dhaka-1207 </span><br /><span className='ml-6'> Bangladesh</span>
                                </div>
                                <div className='mb-3'>
                                    <FontAwesomeIcon className='mr-2' icon={faPhoneFlip} />
                                    +88 01675-363074 <br /> <span className='ml-6'>+88 01771-779948</span>
                                </div>
                                <div className='mb-3'>
                                    <FontAwesomeIcon className='mr-2' icon={faEnvelope} />
                                    <a href='info@syncseeker.com'>info@syncseeker.com</a>
                                </div>
                                <div className='mb-3'>
                                    <FontAwesomeIcon className='mr-2' icon={faGlobe} />
                                    <a href='www.syncseeker.com'>syncseeker.com</a>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-2">

                                <a href="#!" role="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-7 h-7" style={{ color: '#1877f2' }}><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" /></svg>
                                </a>

                                <a href="#!" role="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-7 h-7" style={{ color: '#c13584' }}><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                </a>

                                <a href="#!" role="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-7 h-7" style={{ color: '#0077b5' }}><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
                                </a>

                                <a href="#!" role="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="w-7 h-7" style={{ color: '#ff0000' }}><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></svg>
                                </a>

                            </div>
                        </div>

                        <div>
                            <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
                                <div class="mb-3 px-5">
                                    <h1 className='mb-6 text-3xl text-start '>Get in touch</h1>
                                    <input
                                        type="text"
                                        class="mb-5 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        id="exampleFormControlInput1"
                                        placeholder="Name"
                                    />
                                    <input
                                        type="text"
                                        class="mb-5 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        id="exampleFormControlInput1"
                                        placeholder="Email Address"
                                    />
                                    <input
                                        type="text"
                                        class="mb-5 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        id="exampleFormControlInput1"
                                        placeholder="Phone Number"
                                    />
                                    <select class="mb-5 form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example">
                                        <option selected>Select country</option>
                                        <option value="1">Bangladesh</option>
                                        <option value="2">India</option>
                                        <option value="3">China</option>
                                    </select>
                                    <textarea
                                        class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        placeholder="Description"
                                    ></textarea>
                                </div>
                                <button type="button" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Submit</button>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    );
};

export default ContactBanner;