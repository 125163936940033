import React from 'react';
import management from '../../Assets/Images/top-products/top-product.png';
import commercial from '../../Assets/Images/top-products/commercial.png';
const TopProducts1 = () => {
    return (
        <div className='bg-sky-600'>
            <section className='container mx-auto px-6 relative top-0 '>
                <div className='grid lg:grid-cols-2 gap-4 w-5/6 mx-auto'>
                    <div className='grid grid-cols-2 gap-2 my-10'>
                        <div className=''>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>

                        </div>
                        <div className=''>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>
                            <div className='flex justify-center items-center my-3'>
                                <img className='w-14 h-14' src={commercial} alt='commercial' />
                                <p className='text-base font-normal text-white ml-2'>Enterprise Asset Management</p>
                            </div>

                        </div>


                    </div>
                    <div>
                        <img src={management} alt=''></img>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default TopProducts1;