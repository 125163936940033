import React from 'react';

const SoftDevelop = () => {
    return (
        <div className='container my-12 px-6 mx-auto '>

            <section className='grid lg:grid-cols-2 gap-5  '>
                <div className='p-2'>
                    <h1 className='text-5xl font-bold text-start mb-6'>Software Development Services</h1>
                    <p className=' text-3xl'>
                        Since 1994 the team at One Beyond has been developing custom written bespoke software applications for established businesses, blue chips and start-ups.
                        We combine the very best technical platforms, including the Microsoft .NET stack, MEAN stack, Azure, AWS, leveraging our Waterloo development technology 
                        to deliver solutions quickly and with world-class quality.
                    </p>
                </div>
                <div className='flex justify-center items-center'>
                    <img className='rounded-lg' src='https://images.pexels.com/photos/3861958/pexels-photo-3861958.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='history' />
                </div>
            </section>
        </div>
    );
};

export default SoftDevelop;