import React from 'react';

const MainSlider = () => {
    return (
        <div id="carouselExampleCaptions" className="carousel slide " data-bs-ride="carousel">
            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-24">
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                ></button>
                <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                ></button>
            </div>


            <div className="carousel-inner relative w-full overflow-hidden">
                <div className="carousel-item active relative float-left w-full">
                    <div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover"
                            style={{
                                backgroundPosition: '50%',
                                backgroundImage: `url('https://mdbcdn.b-cdn.net/img/new/slides/146.webp')`,
                                height: '800px'
                            }}
                        >
                            <div
                                className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                            >
                                <div className="flex justify-center items-center h-full">
                                    <div className="text-center text-white px-6 md:px-12">
                                        <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
                                            The best offer on the market <br /><span>for your business</span>
                                        </h1>
                                        <a className="inline-block px-7 py-3 mr-1.5 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded-full shadow-md hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#!" role="button">Get started</a>
                                        <a className="inline-block px-7 py-3 border-2 border-transparent bg-transparent text-white font-medium text-sm leading-snug uppercase rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#!" role="button">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item relative float-left w-full">
                    <div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover"
                            style={{
                                backgroundPosition: '50%',
                                backgroundImage: `url('https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg')`,
                                height: '800px'
                            }}
                        >
                            <div
                                className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                            >
                                <div className="flex justify-center items-center h-full">
                                    <div className="text-center text-white px-6 md:px-12">
                                        <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
                                            The best offer on the market <br /><span>for your business</span>
                                        </h1>
                                        <button
                                            type="button"
                                            className="inline-block px-7 py-3 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                            data-mdb-ripple="true"
                                            data-mdb-ripple-color="light"
                                        >
                                            Get started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item relative float-left w-full">
                    <div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover"
                            style={{
                                backgroundPosition: '50%',
                                backgroundImage: `url('https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(35).webp')`,
                                height: '800px'
                            }}
                        >
                            <div
                                className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                            >
                                <div className="flex justify-center items-center h-full">
                                    <div className="text-center text-white px-6 md:px-12">
                                        <h1 className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">
                                            The best offer on the market <br /><span>for your business</span>
                                        </h1>
                                        <button
                                            type="button"
                                            className="inline-block px-7 py-3 border-2 border-white text-white font-medium text-sm leading-snug uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                                            data-mdb-ripple="true"
                                            data-mdb-ripple-color="light"
                                        >
                                            Get started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default MainSlider;