import React from 'react';
import AboutBanner from '../../Components/About/AboutBanner';
import Leadership from '../../Components/About/Leadership';
import OurExperience from '../../Components/About/OurExperience';
import OurGoal from '../../Components/About/OurGoal';
import OurHistory from '../../Components/About/OurHistory';


const About = () => {
    return (
        <div>
            <AboutBanner/>
            <OurHistory/>
            <OurExperience/>
            <OurGoal/>
            <Leadership/>
        </div>
    );
};

export default About;