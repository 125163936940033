import React, { useState } from 'react';
import { Link } from "react-router-dom";
import navlogo from "../../Assets/Images/sync-logo.jpeg";
const Navbar = () => {
    const [myNavbar, setMyNavbar] = useState(false);
    const changeNavbar = () => {
        if (window.scrollY >= 7) {
            setMyNavbar(true)
        } else {
            setMyNavbar(false)
        }
    }
    window.addEventListener('scroll', changeNavbar);
    return (
        <div>
            <nav className={
                myNavbar
                    ? 'navbar navbar-expand-md shadow-lg py-2 bg-white flex items-center w-full justify-between fixed top-0 right-0 left-0 z-10 transition duration-700 ease-in-out'
                    : 'navbar navbar-expand-md py-2 bg-transparent flex items-center w-full justify-between fixed top-0 right-0 left-0 z-10 transition duration-700 ease-in-out'
            }>
                <div className="px-6 w-5/6 mx-auto flex flex-wrap items-center justify-between">

                    <div className="flex items-center">
                        <button className="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out mr-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentY" aria-controls="navbarSupportedContentY" aria-expanded="false" aria-label="Toggle navigation">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                            </svg>
                        </button>
                        <a className="navbar-brand text-blue-600" href="#!">
                            <img className='rounded' src={navlogo} alt='navlogo' style={{ width: '60px', height: '50px' }} />
                        </a>
                    </div>

                    <div className="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
                        <div className=" lg:ml-auto">
                            <ul className="navbar-nav mr-auto lg:flex lg:flex-row">
                                <li className="nav-item mx-4 ">
                                    <Link className={
                                        myNavbar
                                            ? 'nav-link block text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                            : 'nav-link block text-lg pr-2 lg:px-2 py-2 text-white hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                    } to='/' data-mdb-ripple="true" data-mdb-ripple-color="light">Home</Link>
                                </li>
                                <li className="nav-item mx-4 ">
                                    <Link className={
                                        myNavbar
                                            ? 'nav-link block text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                            : 'nav-link block text-lg pr-2 lg:px-2 py-2 text-white hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                    } to='/about' data-mdb-ripple="true" data-mdb-ripple-color="light">About</Link>
                                </li>
                                <li className="nav-item mx-4 ">
                                    <Link className={
                                        myNavbar
                                            ? 'nav-link block text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                            : 'nav-link block text-lg pr-2 lg:px-2 py-2 text-white hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                    } to='/services' data-mdb-ripple="true" data-mdb-ripple-color="light">Services</Link>
                                </li>
                                <li className="nav-item mx-4 ">
                                    <Link className={
                                        myNavbar
                                            ? 'nav-link block text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                            : 'nav-link block text-lg pr-2 lg:px-2 py-2 text-white hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                    } to='/products' data-mdb-ripple="true" data-mdb-ripple-color="light">Products</Link>
                                </li>
                                <li className="nav-item mx-4 ">
                                    <Link className={
                                        myNavbar
                                            ? 'nav-link block text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                            : 'nav-link block text-lg pr-2 lg:px-2 py-2 text-white hover:text-red-700 focus:text-red-700 focus:underline transition duration-150 ease-in-out'
                                    } to='/contact' data-mdb-ripple="true" data-mdb-ripple-color="light">Contact</Link>
                                </li>
                                 
                            </ul>
                        </div>
                    </div>

                </div>
            </nav>
        </div>
    );
};

export default Navbar;