import React from 'react';
import goal from '../../Assets/Images/about/our-goal-2.jpg';
const OurGoal = () => {
    return (
        <div className='container my-12 px-6 mx-auto '>
            <div className='text-center'>
                <h1 className='text-5xl font-semibold mb-6'>Your Success… Our Business</h1>
                <div className='flex justify-center items-center '>
                <p className='mb-6 p-4 text-xl w-2/3'>Specializing in the areas of IT Recruitment, ERP application implementation, Business Analysis, Quality Assurance and software application development services.</p>
                </div>
            </div>
            <section className='grid lg:grid-cols-2 gap-5 '>
                <div>
                    <img className='rounded-lg' src={goal} alt='history' />
                </div>
                <div className='text-lg text-start '>
                    <h1 className='text-3xl  text-blue-900 text-start mb-6'>Our goal</h1>
                    <h1 className='text-5xl font-semibold mb-6'>Deliver quality services that can integrate and optimize technology</h1>
                    <p className='mb-6'>To expand our digital footprint and bring the best services to customers, Acolade and Marlabs Inc (Head quartered in New Jersey, USA www.marlabs.com) have formed a strategic partnership leveraging each others services and platforms to deliver the best services to our customers.</p>
                    <p className='mb-6'>The partnership brings the expertise of 2500 associates with worldwide centres in Australia, United States, and India. We are nimble and cognizant of what our customers want and invest heavily in our people supported by a platform driven approach that brings high efficiencies in what we do.</p>
                </div>
            </section>
        </div>
    );
};

export default OurGoal;