import React from 'react';

const OurHistory = () => {
    return (
        <div className='container my-12 px-6 mx-auto '>
            <h1 className='text-3xl font-bold text-blue-900 text-center mb-6'>Our History</h1>
            <section className='grid lg:grid-cols-2 gap-5 '>
                <div>
                    <img className='rounded-lg' src='https://img.freepik.com/free-vector/history-grunge-text_460848-9367.jpg?w=996&t=st=1659535585~exp=1659536185~hmac=1b3f3b35d9150709ca33c4916ee0ecace71c873f252347821957abaa090f43ea' alt='history' />
                </div>
                <div className='p-2 text-lg'>
                    <p className='mb-6 '>Sync Software was established in 2012 by a group of IT professionals who had a passion for teaching and who wanted to pass on the knowledge they had accumulated over a period of 10 years to people who want to make a career in the IT industry.</p>
                    <p className='mb-6'>The word Advanto is derived from the words Advantage and Technology. We combined them to form a name that would portray our belief that if you have an advantage of technology, you can do a lot of things in the IT industry</p>
                    <p className='mb-6'>We wanted to provide the industry with people having good skills and people with the knowledge and ability to make a career in the IT industry. That is how we decided to transition from Peers to Advanto!</p>
                    <p className='mb-6'>To provide training to candidates in various technologies by experts who have real-time industry knowledge so that candidates can start their career and be in sync with the industry.</p>
                    <p className='mb-6'>To provide candidates with knowledge that is at-par with the IT industry, but also in a pocket friendly way so that everyone can take benefit of our courses.</p>
                </div>
            </section>
        </div>
    );
};

export default OurHistory;