import React from 'react';

const FantasyApp = () => {
    return (
        <div className='container my-12 px-6 mx-auto'>
            <h1 className='text-5xl font-bold ml-36 my-12'>Fantasy Sports App</h1>
            <div className='flex justify-center items-center'>
                <img className='w-5/6 rounded-lg shadow-2xl shadow-red-500/50' src='https://cdn-bcikh.nitrocdn.com/VCMuauOnKdIuGHOdXXWcAEouHFNRgAdk/assets/static/optimized/rev-71a5585/wp-content/uploads/2021/03/fantasy-sports-app-theme-riseuplabs-1050x540.jpeg' alt=''></img>
            </div>
            <div className='grid lg:grid-cols-2 gap-4 w-5/6 mx-auto '>
                <div className='p-6'>
                    <h5 className='text-xl pt-6'>
                        Fantasy Sports are online prediction games where you put together a virtual team of real sports players. Start your business today on Fantasy Sports application and earn money from your users.
                    </h5>
                    <button type="button" class="my-10 w-64 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-lg leading-normal capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">See More</button>
                </div>
                <div className='' >
                    <div class="flex justify-center lg:-mt-10" >
                        <div class="block p-6 rounded-lg shadow-lg bg-white w-5/6">
                            <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Multi-Platform</h5>
                            <hr />
                            <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Localization</h5>
                            <hr />
                            <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Payment Gateway</h5>
                            <hr />
                            <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Maintenance</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FantasyApp;