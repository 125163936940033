import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
const OurFeatures = () => {
    return (
        <div className='container my-12 px-6 w-5/6 mx-auto'>
            <h1 className='text-3xl font-bold text-blue-900 text-center mb-6'>Our Features</h1>
            <section className='grid lg:grid-cols-3 gap-5 '>
                <div className="flex justify-center  border-4 border-gray-200 ">
                    <div className=" p-6 bg-white ">
                        <div className='flex justify-center items-center'>
                            <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                        </div>
                        <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-6">Automation Testing Services</h5>
                        <ul>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Reduced delivery time</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Reduced delivery time</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Reduced delivery time</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Reduced delivery time</li>
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center border-4 border-gray-200 ">
                    <div className=" p-6 bg-white ">
                        <div className='flex justify-center items-center'>
                            <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                        </div>
                        <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-6">Automation Testing Services</h5>
                        <ul>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />UI/UX Testing</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />UI/UX Testing</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />UI/UX Testing</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />UI/UX Testing</li>
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center border-4 border-gray-200 ">
                    <div className=" p-6 bg-white ">
                        <div className='flex justify-center items-center'>
                            <img src="https://static.vecteezy.com/system/resources/thumbnails/006/173/028/small/computer-repair-logo-template-computer-pixels-logo-software-development-design-free-vector.jpg" className="rounded-full w-32" alt="Avatar" />
                        </div>
                        <h5 className="text-gray-900 text-xl text-center leading-tight font-medium mb-6">Automation Testing Services</h5>
                        <ul>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Use the latest framework</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Use the latest framework</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Use the latest framework</li>
                            <li className='my-1'><FontAwesomeIcon className='mr-2' icon={faCircleCheck} />Use the latest framework</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OurFeatures;