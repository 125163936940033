import React from 'react';
import clients from '../../Assets/Images/clients-1.png'
const SyncClients = () => {
    return (
        <section className='container mt-5'>
            <h1 className='text-3xl font-bold text-center'>Key Client Portfolio</h1>
            <div className='flex justify-center items-center'>
                <img className='' src={clients} alt='clients'></img>
            </div>
        </section>
    );
};

export default SyncClients;