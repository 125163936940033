import React from 'react';
import syncdata from '../../Assets/Images/about/sync-data.png';
const OurExperience = () => {
    return (
        <div className='container my-12 px-6 mx-auto '>
            <h1 className='text-3xl  text-blue-900 text-start mb-6'>Our Experience</h1>
            <section className='grid lg:grid-cols-2 gap-5 '>
                <div className='text-lg text-start '>
                    <h1 className='text-5xl font-semibold mb-6'>Providing specialized IT services to a range of businesses, from small to large corporations</h1>
                    <p className='mb-6'>Our experience includes providing specialized IT services to a range of businesses, from small to large corporations interms of size and turnover over a variety of industry verticals. An end-to-end solution provider, that brings the best of both worlds.</p>
                    <p className='mb-6'>To expand our digital footprint and bring the best services to customers, Acolade and Marlabs Inc (Head quartered in New Jersey, USA www.marlabs.com) have formed a strategic partnership leveraging each others services and platforms to deliver the best services to our customers.</p>
                    <p className='mb-6'>The partnership brings the expertise of 2500 associates with worldwide centres in Australia, United States, and India. We are nimble and cognizant of what our customers want and invest heavily in our people supported by a platform driven approach that brings high efficiencies in what we do.</p>
                </div>
                <div >
                    <img className='rounded-lg h-5/6' src={syncdata} alt='history' />
                </div>
            </section>
        </div>
    );
};

export default OurExperience;