import React from 'react';

const AboutBanner = () => {
    return (
        <div>
            <div className="p-12 text-center relative overflow-hidden bg-no-repeat bg-cover "
                style={{
                    backgroundImage: `url('https://mdbcdn.b-cdn.net/img/new/slides/041.webp')`,
                    height: '400px'
                }} >
                <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                    <div className="flex justify-center items-center h-full">
                        <div className="text-white">
                            <h2 className="font-bold text-5xl md:text-6xl xl:text-7xl mb-4">About Us</h2>
                            <h4 className="font-semibold text-xl mb-6">
                                Creating technology solutions that enable businesses to achieve success.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AboutBanner;