import React from 'react';

const OurServices = () => {
    return (
        <div className='bg-slate-600 '>
            <div className="container  my-24 px-6 mx-auto">

                <section className="mb-32  text-gray-800 text-center">
                    <h1 className='text-3xl font-bold text-white text-center  my-12'>Our Services</h1>
                    <div className='w-5/6 mx-auto'>
                        <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-x-1 lg:gap-xl-12">

                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Custom Software Development</p>
                            </div>
                            <div className="mb-12">
                                <img src="https://static.vecteezy.com/system/resources/thumbnails/003/573/245/small/computer-repair-logo-template-software-development-design-desktop-service-vector.jpg" className="rounded-full mx-auto shadow-lg mb-4" alt=""
                                    style={{ maxWidth: '100px' }} />

                                <p className="font-bold mb-2 text-white">Cloud Engineering</p>
                            </div>


                        </div>
                    </div>
                </section>


            </div>

        </div>
    );
};

export default OurServices;