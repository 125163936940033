import React from 'react';
import plogo1 from '../../Assets/icons/product-logo-1.PNG';
const ELearning = () => {
    return (
        <div className='container my-12 px-6 mx-auto'>
            <h1 className='text-5xl font-bold ml-36 my-12'>e-Learning</h1>
            <div className='flex justify-center items-center'>
                <img className='w-5/6 rounded-lg shadow-2xl shadow-red-500/50' src='https://cdn-bcikh.nitrocdn.com/VCMuauOnKdIuGHOdXXWcAEouHFNRgAdk/assets/static/optimized/rev-71a5585/wp-content/uploads/2021/05/e-learning-platform-thumbnail.jpg' alt=''></img>
            </div>
            <div className='grid lg:grid-cols-2 gap-4 w-5/6 mx-auto 0'>
                <div className='' >
                    <div class="flex justify-center -mt-10" >
                        <div class="block p-6 rounded-lg shadow-lg bg-white w-5/6">
                            <div className='flex items-center'>
                                <img className='w-10 h-10' src={plogo1} alt='' />
                                <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Front-end Solution</h5>
                            </div>
                            <hr />
                            <div className='flex items-center'>
                                <img className='w-10 h-10' src={plogo1} alt='' />
                                <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">Back-end support</h5>
                            </div>
                            <hr />
                            <div className='flex items-center'>
                                <img className='w-10 h-10' src={plogo1} alt='' />
                                <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">WordPress Solution</h5>
                            </div>
                            <hr />
                            <div className='flex items-center'>
                                <img className='w-10 h-10' src={plogo1} alt='' />
                                <h5 class="text-gray-900 text-lg leading-tight font-medium my-6">eLearning Mobile App</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' p-6'>
                    <h5 className='text-xl pt-6'>
                        Our eLearning management system is a software application for educational courses, training programs, learning and development programs, etc. You worked hard to prepare the lessons for your courses, to be presented in the most organizing and engaging way to your learners.
                    </h5>
                    <button type="button" class="my-12 w-64 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-lg leading-normal capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">See More</button>
                </div>

            </div>
        </div>
    );
};

export default ELearning;