import React from 'react';
import ContactBanner from '../../Components/Contact/ContactBanner';
import ContactMap from '../../Components/Contact/ContactMap';

const Contact = () => {
    return (
        <div>
            <ContactBanner/>
            <ContactMap/>
        </div>
    );
};

export default Contact;