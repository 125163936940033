import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDesktop, faCartPlus,
    faHouseChimney, faGraduationCap, faHandHoldingHeart,
    faCartShopping, faHeart, faTowerCell, faFileInvoiceDollar,
    faBraille, faRocket, faHouseFloodWaterCircleArrowRight
} from '@fortawesome/free-solid-svg-icons';
const Serve = () => {
    return (
        <div className='relative top-0'>
            <h1 className=' text-5xl text-center font-bold my-4'>Industries We Serve</h1>
            <div className='bg-blue-900 '>
                <div className='container mx-auto px-6 text-white text-center'>

                    <div className='py-24 w-5/6 mx-auto'>
                        <div className='grid lg:grid-cols-6 gap-1 '>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faHouseChimney} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Real State</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faGraduationCap} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Education</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-3' icon={faCartShopping} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Retail </h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faTowerCell} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Telco</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faHouseFloodWaterCircleArrowRight} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Automotive</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-3' icon={faDesktop} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Software </h6>
                            </div>
                        </div>
                        <div className='grid lg:grid-cols-6 gap-1 lg:mt-24'>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faHeart} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Non-Profit</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faCartPlus} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>E-com</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-3' icon={faHandHoldingHeart} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Healthcare</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faFileInvoiceDollar} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Fintech</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-4' icon={faBraille} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Miscellaneous</h6>
                            </div>
                            <div className='flex flex-col '>
                                <FontAwesomeIcon className='mx-3' icon={faRocket} size="2x" />
                                <h6 className='text-xl font-bold mt-10'>Startup</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Serve;