import React from 'react';
import contact from '../../Assets/Images/contact-banner-1.jpg';
const SyncContact = () => {
    return (
        <section className=" overflow-hidden bg-no-repeat bg-cover bg-base-100 relative top-0 "
            style={{
                backgroundPosition: '50%',
                backgroundImage: `url(${contact})`,
                height: 'auto',

            }}>

            <div className="container my-12 px-6 mx-auto">

                <section className="mb-32 text-gray-800 text-center md:text-left">

                    <div className="flex flex-wrap justify-center">
                        <div className="grow-0 shrink-0 basis-auto w-full lg:w-10/12 px-3">
                            <p className=''>Support Online</p>
                            <h1 className='text-4xl font-bold mb-5'>Get a Contact</h1>
                            <div className="mb-6 mt-3 md:mb-0">
                                <div className="md:flex flex-row">
                                    <input type="text"
                                        className="form-control block w-full px-4 py-2 mb-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter your email" />
                                    <input type="text"
                                        className="form-control block w-full px-4 py-2 mb-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter your email" />
                                    <input type="text"
                                        className="form-control block w-full px-4 py-2 mb-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        placeholder="Enter your email" />

                                    <button type="submit"
                                        className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                        data-mdb-ripple="true" data-mdb-ripple-color="light">
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </div>

        </section>
    );
};

export default SyncContact;