import React from 'react';

const ContactMap = () => {
    return (
        <div className='container my-12 px-6 mx-auto'>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1825.7442733349983!2d90.3524819!3d23.7656113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c09f9ba3d447%3A0x1babce9f1c6c95a3!2z4Kau4KeL4Ka54Ka-4Kau4KeN4Kau4Kam4Kaq4KeB4KawLCDgpqLgpr7gppXgpr4!5e0!3m2!1sbn!2sbd!4v1659973623746!5m2!1sbn!2sbd"
                className='w-full h-screen' style={{ border: 0 }} allowFullScreen="" loading="lazy" referreolicy="no-referrer-when-downgrade" title='google-map'>

            </iframe>
        </div>
    );
};

export default ContactMap;