import React from 'react';

const Footer = () => {
    return (
        <div className='' style={{ backgroundColor: '#0a4275' }}>
            <section className='container mx-auto px-6 ' >
                <footer className="text-center text-white" >
                    <div className="container p-6">
                        <div className="grid lg:grid-cols-5 md:grid-cols-2">
                            <div className="mb-6">
                                <h5 className="uppercase font-bold mb-2.5 ">SYNC-SEEKER</h5>

                                <ul className="list-none mb-0">
                                    <li>
                                        <a href="#!" >Link 1</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 2</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 3</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 4</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-6">
                                <h5 className="font-bold mb-2.5 ">Services</h5>

                                <ul className="list-none mb-0">
                                    <li>
                                        <a href="#!" >Link 1</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 2</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 3</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 4</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-6">
                                <h5 className=" font-bold mb-2.5 ">Expertise</h5>

                                <ul className="list-none mb-0">
                                    <li>
                                        <a href="#!" >Link 1</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 2</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 3</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 4</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-6">
                                <h5 className=" font-bold mb-2.5 ">Industries</h5>

                                <ul className="list-none mb-0">
                                    <li>
                                        <a href="#!" >Link 1</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 2</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 3</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 4</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-6">
                                <h5 className=" font-bold mb-2.5 ">Company</h5>

                                <ul className="list-none mb-0">
                                    <li>
                                        <a href="#!" >Link 1</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 2</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 3</a>
                                    </li>
                                    <li>
                                        <a href="#!" >Link 4</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        © 2022 Copyright:
                        <a className="text-white" href="https://tailwind-elements.com/"> Sync Seeker</a>
                    </div>
                </footer>
            </section>
        </div>
    );
};

export default Footer;