import React from 'react';

import SyncClientsReview from '../../Components/Home/SyncClientsReview';
import SyncClients from '../../Components/Home/SyncClients';
import SyncContact from '../../Components/Home/SyncContact';
import SyncInfo from '../../Components/Home/SyncInfo';
import Tabs from '../../Components/Home/Tabs';
import MainSlider from '../../Components/Home/MainSlider';
import Serve from '../../Components/Home/Serve';
import Business from '../../Components/Home/Business';


const Home = () => {
    return (
        <div>
            <MainSlider/>
            <Serve/>
            <Tabs/>
            <Business/>
            <SyncInfo></SyncInfo>
            <SyncClients></SyncClients>
            <SyncContact></SyncContact>
            <SyncClientsReview></SyncClientsReview>
        </div>
    );
};

export default Home;