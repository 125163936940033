import React from 'react';
import banner from '../../Assets/Images/business-banner-3.jpg';
const Business = () => {
    return (
        <div >
            <div className="overflow-hidden bg-no-repeat bg-cover relative top-0" style={{
                backgroundPosition: '50%',
                backgroundImage: `url(${banner})`,

            }}>

                <section className=" container my-12 px-6 mx-auto ">
                    <h1 className='text-center text-2xl pt-2 mt-4'>Integrate, Secure & Scale Your Business</h1>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-9  mx-24 my-6">

                        <div className='box-border h-64 w-64 p-4 border-2 border-indigo-200'>
                            <div className='flex justify-center items-center'>
                                <img className='rounded-full w-36 h-36' src='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324' alt=''></img>
                            </div>
                            <h1 className='text-xl text-center'>ERP & Business Sofware</h1>
                        </div>
                        <div className='box-border h-64 w-64 p-4 border-2 border-indigo-200'>
                            <div className='flex justify-center items-center'>
                                <img className='rounded-full w-36 h-36' src='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324' alt=''></img>
                            </div>
                            <h1 className='text-xl text-center'>ERP & Business Sofware</h1>
                        </div>
                        <div className='box-border h-64 w-64 p-4 border-2 border-indigo-200'>
                            <div className='flex justify-center items-center'>
                                <img className='rounded-full w-36 h-36' src='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324' alt=''></img>
                            </div>
                            <h1 className='text-xl text-center'>ERP & Business Sofware</h1>
                        </div>
                        <div className='box-border h-64 w-64 p-4 border-2 border-indigo-200'>
                            <div className='flex justify-center items-center'>
                                <img className='rounded-full w-36 h-36' src='https://d1csarkz8obe9u.cloudfront.net/posterpreviews/business-logo-design-template-78655edda18bc1196ab28760f1535baa_screen.jpg?ts=1617645324' alt=''></img>
                            </div>
                            <h1 className='text-xl text-center'>ERP & Business Sofware</h1>
                        </div>

                    </div>
                    <div className='flex justify-center mb-4'>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Contact Us
                        </button>
                    </div>
                </section>


            </div>

        </div>
    );
};

export default Business;