import React from 'react';

const Leadership = () => {
    return (
        <div>

            <div className="container my-12 px-6 mx-auto">


                <section className="mb-32 text-gray-800 text-center p-5">
                    <h2 className="text-3xl font-bold mb-12">Meet the <u className="text-blue-600">team</u></h2>

                    <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-3 lg:gap-xl-12">
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/2.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start text-start'>
                                    <p className="text-2xl font-bold mb-2">Walif</p>
                                    <p className="text-lg text-gray-500">CEO</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/5.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start'>
                                    <p className="text-2xl font-bold mb-2">Lisa Ferrol</p>
                                    <p className="text-lg text-gray-500">Web designer</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/6.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start'>
                                    <p className="text-2xl font-bold mb-2">Maria Smith</p>
                                    <p className="text-lg text-gray-500">Senior consultant</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/2.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start'>
                                    <p className="text-2xl font-bold mb-2">John Doe</p>
                                    <p className="text-lg text-gray-500">Co-founder</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/5.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start'>
                                    <p className="text-2xl font-bold mb-2">Lisa Ferrol</p>
                                    <p className="text-lg text-gray-500">Web designer</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>
                        <div className="mb-6 border-2 border-gray-200">
                            <div className='flex justify-start items-center p-3'>
                                <div >
                                    <img src="https://mdbootstrap.com/img/new/avatars/6.jpg" className="rounded-full mx-auto shadow-lg" alt=""
                                        style={{ maxWidth: '100px' }} />
                                </div>
                                <div className='ml-4 text-start'>
                                    <p className="text-2xl font-bold mb-2">Maria Smith</p>
                                    <p className="text-lg text-gray-500">Senior consultant</p>
                                </div>
                            </div>
                            <div className='text-start  p-3'>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget molestie tortor. Donec at rutrum lorem. Duis volutpat placerat dolor in ultrices. </p>
                            </div>
                        </div>

                    </div>
                </section>


            </div>

        </div>
    );
};

export default Leadership;