import React from 'react';
import Navbar from '../../Components/Navbar/Navbar';



const Header = () => {
    return (
        <div>
            <Navbar></Navbar>
            
            
        </div>
    );
};

export default Header;